<template>
  <div class="pb-12">
    <v-container 
      fluid
    >
      <h2 class="mb-10 pb-2 title-text" >News</h2>
      <v-row>
        <v-col 
          cols="12"
          class="article"
        >
          <div class="act-detail details">
            <div 
              class="act-item"
              :class="{ 'pl-0 pr-0': $vuetify.display.mobile }"
            >
              <div>
                <h2 class="mb-2">Caring Company Award Presentation to LeachGarner Hong Kong</h2>
                <hr class="title-border">
                <div class="pt-6 pb-4 mb-4 desc">
                  <p>20 May 2019</p>
                </div>
                <v-row>
                  <v-col
                    class="desc-text"
                    :cols="$vuetify.display.mobile ? 12:8"
                  >
                    <p class="mb-6">Through a year of our efforts, the Hong Kong Council of Social Service (HKCSS) is delighted to award the Caring Company Logo to LeachGarner Hong Kong, in recognition of our commitment in caring for the community, 
                    caring for the employees and the environment over the past year.</p>
                    <p class="mb-6">LeachGarner Hong Kong is now approved to use the Caring Company Logo in our promotional materials with effective on 1st March 2019.</p>
                    <p>The Caring Company Award Presentation was on 20 May 2019.</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    :cols="$vuetify.display.mobile ? 12:8"
                  >
                    <v-img 
                      :src="require('@/assets/awards/Caring Company 1.jpg')"
                      alt="Caring Company 1"
                      width="100%" 
                      height="100%" 
                      contain
                    >
                    </v-img>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    :cols="$vuetify.display.mobile ? 12:11"
                  >
                    <v-img 
                      :src="require('@/assets/awards/Caring company logo.jpg')"
                      alt="Caring company logo"
                      width="30%" 
                      height="50%" 
                    >
                    </v-img>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>

export default {
  name: "AwardDetailMay2019Page",
}
</script>
