<template>
  <div class="pb-12">
    <v-container 
      fluid
    >
      <h2 class="mb-10 pb-2 title-text" >News</h2>
      <v-row>
        <v-col 
          cols="12"
          class="article"
        >
          <div class="act-detail details">
            <div 
              class="act-item"
              :class="{ 'pl-0 pr-0': $vuetify.display.mobile }"
            >
              <div>
                <h2 class="mb-2">Good MPF Employer Award 2023</h2>
                <hr class="title-border">
                <div class="pt-6 pb-4 mb-4 desc">
                  <p>10 October 2023</p>
                </div>
                <v-row>
                  <v-col
                    class="desc-text"
                    :cols="$vuetify.display.mobile ? 12:11"
                  >
                    <p>Leach & Garner (HK) Limited is honored to receive the Good MPF Employer Award 2023 
                    organized by the Mandatory Provident Fund Schemes Authority (MPFA).
                    The award aims to cultivate employers’ responsibility under the law, and give 
                    recognition to employers who strive to enhance retirement protection for their employees.</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    :cols="$vuetify.display.mobile ? 12:11"
                  >
                    <v-img 
                      :src="require('@/assets/awards/LG_GoodEmployee2023.jpg')"
                      alt="LG Good Employee"
                      width="100%" 
                      height="100%" 
                      contain
                    >
                    </v-img>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>

export default {
  name: "AwardDetailOct2023Page",
}
</script>
