<template>
  <v-app>
    <v-app-bar class="light-theme">
      <v-container 
        fluid
        height="100%"
        class="pa-0"
      >
        <v-row
          v-if="$vuetify.display.smAndDown"
          no-gutters
          class="justify-center mb-1"
        >
        <div class="text-right languages">
          <v-icon icon="mdi-web"></v-icon>
          Languages
        </div>
        </v-row>
        <v-row 
          no-gutters
          class="px-4"
        >
          <v-col 
            cols="6"
            sm="4"
          >
            <a href="/about">
              <v-img
                :src="logo"
                alt="logo"
                class="logo"
                contain
                max-width="366"
                transition="false"
              />
            </a>
          </v-col>
          <v-col 
            v-if="!$vuetify.display.mobile"
            cols="7"
            offset="1"
            class="align-self-end"
          >
            <div class="text-right languages">
              <v-icon icon="mdi-web"></v-icon>
              Languages
            </div>
            <ul class="d-flex justify-space-between align-end">
              <li v-for="(link, i) in links" class="nav-item" :key="i">
                <a
                  v-if="!link.subLinks"
                  class="nav-link" 
                  :href="$router.resolve({path: link.to}).href"
                >
                  {{ link.text }}
                </a>
                <v-menu
                  v-if="link.subLinks"
                  attach
                  eager
                  :offset-y="$vuetify.display.mdAndUp"
                  :open-on-hover="true"
                  open-delay="250"
                  transition="slide-y-transition"
                >
                  <template #activator="{props}">
                    <a
                      class="nav-link" 
                      :href="$router.resolve({path: link.to}).href"
                      v-bind="props"
                    >
                      {{ link.text }}
                    </a>
                  </template>

                  <v-list>
                    <v-list-item
                      v-for="(item, index) in link.subLinks"
                      :key="index"
                      :value="index"
                    >
                      <a
                        class="nav-link" 
                        :href="$router.resolve({path: item.to , query: item.query}).href"
                      >
                        {{ item.text }}
                      </a>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </li>
            </ul>
          </v-col>
          <v-col 
            v-if="$vuetify.display.mobile"
            class="justify-end align-center d-flex drawer"
          >
            <v-app-bar-nav-icon 
              aria-label="Open Mobile Menu"
              @click.stop="drawer = !drawer"
              size="x-large"
            >
            </v-app-bar-nav-icon>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-navigation-drawer
      v-if="$vuetify.display.mobile"
      v-model="drawer" 
      clipped
      width=240 
      sticky
    >
      <v-list
        flat 
        dense 
        nav 
        class="py-1"
      >
        <div v-for="(link, i) in links" :key="i">
          <v-list-group v-if="link.subLinks">
            <template #activator="{props}">
              <v-list-item v-bind="props">{{ link.text }}</v-list-item>
            </template>
            <v-list-item
              v-for="(sub, ii) in link.subLinks"
              :key="ii"
              dense
            >
              <a :href="$router.resolve({path: sub.to , query: sub.query}).href">
                {{ sub.text }}
              </a>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            dense
            :title="link.text"
            :to="link.to"
          >
          </v-list-item>
        </div> 
      </v-list>
    </v-navigation-drawer>
    <router-view 
      class="content"
    />
    <Footer v-if="showFooter"/>
  </v-app>
</template>

<script>
import logo from "@/assets/logo.png"
import Footer from "@/views/FooterPage.vue"

export default {
  components: {
    Footer
  },
  data() {
    return {
      showFooter: false,
      drawer: false,
      logo: logo,
      links: [
        {
          to     : '/about',
          text   : 'About Us',
        },
        {
          to     : '/products',
          text   : 'Products',
          subLinks : [
              {
                  text : 'New Products',
                  to    : '/products?newProducts=true',
                  query : {"new": "true"}
              },
              {
                  text : 'Findings',
                  to    : '/products?cat=Findings',
                  query : {"cat": "Findings"}
              },
              {
                  text : 'Chains',
                  to    : '/products?cat=Chains',
                  query : {"cat": "Chains"}
              },
              {
                  text : 'Mills',
                  to    : '/products?cat=Mills',
                  query : {"cat": "Mills"}
              },
              {
                  text : 'Inverness',
                  to    : '/inverness',
              },
          ]
        },
        {
          to     : '/about',
          text   : 'Guide & Reference',
                    subLinks : [
              {
                  text : 'Conversion Tables',
                  to    : '/conversion',
              },
              {
                  text : 'Gold Filled Vs. Gold Plated',
                  to    : '/gold',
              },
              {
                  text : 'LeachGarner Video',
                  to    : '/videos',
              },
          ]
        },
        {
          to     : '/news',
          text   : 'News, Awards & Cert.',
                    subLinks : [
              {
                  text : 'News',
                  to    : '/news',
              },
              {
                  text : 'Awards',
                  to    : '/awards',
              },
              {
                  text : 'Certifications',
                  to    : '/certs',
              },
          ]
        },
        {
            to     : '/contact',
            text   : 'Contact Us',
        },
      ]
    };
  },
  mounted() {
    setTimeout(() => {
      this.showFooter = true;
    }, 3000); 
  }
};
</script>

<style>
.light-theme {
  background-color: #ffffff; 
  color: #be9b59;
}

.light-theme .navbar {
  background-color: #ffffff;
}

.light-theme .nav-link {
  color: #be9b59;
	font-size: 17px;
	display: inline-block;
	text-transform: capitalize;
	font-weight: 600;
}
.languages {
  color: #be9b59;
	font-size: 15px;
}

.light-theme .nav-link:hover {
  color: #7a643b;
}
.v-toolbar {
  padding: 10px 0;
}
.v-toolbar__content {
  height: 80px !important;
}

.navbar {
  list-style-type: none;
  height: 80px;
}

.bi {
  margin-right: 5px;
}
.content {
  margin-top: 120px !important;
  min-height: calc(100vh - 200px);
}
.v-layout {
  background-color: #f9fafc;
}
.v-menu > .v-overlay__content {
	top: 100px !important;
}

body {
  font-family: 'OpenSans', sans-serif;
  transition: background-color 0.3s, color 0.3s;
}
.title-text {
  color: #be9b59;
  font-size: 29px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 1rem;
}

@media screen and (max-width: 960px) {
  .v-toolbar__content {
    height: 75px !important;
  }
  
  .v-list-item__content {
    font-size: 13px;
  }

  .content {
    margin-top: 80px !important;
  }
  .logo, .drawer {
    max-height: 50px;
  }
}

.v-container--fluid {
	max-width: 1200px;
}

.article {
  margin: 0 0 10px 0;
  background: #FFF;
  padding: 12px 15px;
}

.article p {
  font-weight: 600 !important;
}

.title-border {
  border: 1px solid #e7d8af;
}

.details .act-item h2 {
  color: #b38707;
  font-size: 20px;
  line-height: 43px;
  font-weight: 600;
}

.act-detail .act-item {
  background: #FFF;
  padding: 0px 25px 40px 25px;
}

.act-detail .act-item .desc p {
  font-size: 16px;
}

.details p {
  margin-bottom: 0px;
}

.desc-text p {
  font-size: 16px;
  font-family: ariel, helvetica, sans-serif !important;
  font-weight:400;
}

h2.blog-title {
  font-size: 16px;
  font-weight: 600;
  color: #b38707;
  padding-bottom: 10px;
}

</style>
