import { createRouter, createWebHistory } from 'vue-router';
import AboutPage from '../views/AboutPage.vue';
import ProductsPage from '../views/ProductsPage.vue';
import ConversionPage from '../views/ConversionPage.vue';
import GoldTypesPage from '../views/GoldTypesPage.vue';
import LGVideosPage from '../views/LGVideosPage.vue';
import ContactUsPage from '../views/ContactUsPage.vue';
import InvernessPage from '../views/InvernessPage.vue';
import NewsPage from '../views/NewsPage.vue';
import NewsDetailDec2024Page from '../views/news/NewsDetailDec2024Page.vue';
import NewsDetailSept2024Page from '../views/news/NewsDetailSept2024Page.vue';
import NewsDetailAug2024Page from '../views/news/NewsDetailAug2024Page.vue';
import NewsDetailJun2024Page from '../views/news/NewsDetailJun2024Page.vue';
import NewsDetailFeb2024Page from '../views/news/NewsDetailFeb2024Page.vue';
import AwardsPage from '../views/AwardsPage.vue';
import AwardDetailNov2023Page from '../views/awards/AwardDetailNov2023Page.vue';
import AwardDetailOct2023Page from '../views/awards/AwardDetailOct2023Page.vue';
import AwardDetailJun2023Page from '../views/awards/AwardDetailJun2023Page.vue';
import AwardDetailSept2022Page from '../views/awards/AwardDetailSept2022Page.vue';
import AwardDetailJune2022Page from '../views/awards/AwardDetailJune2022Page.vue';
import AwardDetailMay2022Page from '../views/awards/AwardDetailMay2022Page.vue';
import AwardDetailMay2019Page from '../views/awards/AwardDetailMay2019Page.vue';
import CertsPage from '../views/CertsPage.vue';
import CertDetailOct2024Page from '../views/certs/CertDetailOct2024Page.vue';
import CertDetailSept2022Page from '../views/certs/CertDetailSept2022Page.vue';
import NotFoundPage from '@/views/NotFoundPage.vue';

const routes = [
  { path: '/', name: 'Home', component: AboutPage },
  { path: '/about', name: 'About', component: AboutPage },
  { path: '/products', name: 'Products', component: ProductsPage },
  { path: '/conversion', name: 'Conversion', component: ConversionPage },
  { path: '/gold', name: 'Gold', component: GoldTypesPage },
  { path: '/videos', name: 'Videos', component: LGVideosPage },
  { path: '/contact', name: 'contact', component: ContactUsPage },
  { path: '/inverness', name: 'Inverness', component: InvernessPage },
  { path: '/news', name: 'News', component: NewsPage },
  { path: '/newsDec2024', name: 'NewsDec2024', component: NewsDetailDec2024Page },
  { path: '/newsSept2024', name: 'NewsSept2024', component: NewsDetailSept2024Page },
  { path: '/newsAug2024', name: 'NewsAug2024', component: NewsDetailAug2024Page },
  { path: '/newsJun2024', name: 'NewsJun2024', component: NewsDetailJun2024Page },
  { path: '/newsFeb2024', name: 'NewsFeb2024', component: NewsDetailFeb2024Page },
  { path: '/awards', name: 'Awards', component: AwardsPage },
  { path: '/awardsNov2023', name: 'AwardsNov2023', component: AwardDetailNov2023Page },
  { path: '/awardsOct2023', name: 'AwardsOct2023', component: AwardDetailOct2023Page },
  { path: '/awardsJune2023', name: 'AwardsJune2023', component: AwardDetailJun2023Page },
  { path: '/awardsSept2022', name: 'AwardsSept2022', component: AwardDetailSept2022Page },
  { path: '/awardsJune2022', name: 'AwardsJune2022', component: AwardDetailJune2022Page },
  { path: '/awardsMay2022', name: 'AwardsMay2022', component: AwardDetailMay2022Page },
  { path: '/awardsMay2019', name: 'AwardsMay2019', component: AwardDetailMay2019Page },
  { path: '/certs', name: 'Certs', component: CertsPage },
  { path: '/certsOct2024', name: 'CertsOct2024', component: CertDetailOct2024Page },
  { path: '/certsSept2022', name: 'CertsSept2022', component: CertDetailSept2022Page },
  { path: '/404', name: 'notFound', component: NotFoundPage },
  { path: '/:pathMatch(.*)*', redirect: '/404' }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
