<template>
  <v-expansion-panels 
    v-model="expandedPanels"
    class="panel-group"
  >
    <v-expansion-panel
      hide-actions
      class="panel panel-default"
    >
      <v-expansion-panel-title
        class="panel-heading"
      >
        <h4 class="panel-title">Australia</h4>
      </v-expansion-panel-title>
      <v-expansion-panel-text class="panel-body">
        <h4 class="contact-section">Australian Jewellers Supplies Pty Ltd.</h4>
        <p class="mb-6">
          <span>57 Caswell Street
            <br/>
            East Brisbane QLD 4169
            <br/>
            Australia.
            <br/>
            P: +61 1300 852 999
            <br/>
            F: 1-508-222-6531
            <br/>
            E: <a href="mailto:Info@ajsonline.com">Info@ajsonline.com</a>
            <br/>
          </span>
        </p>
      </v-expansion-panel-text>
    </v-expansion-panel>
    <v-expansion-panel
      hide-actions
      class="panel panel-default"
    >
      <v-expansion-panel-title
        class="panel-heading"
      >
        <h4 class="panel-title">China</h4>
      </v-expansion-panel-title>
      <v-expansion-panel-text class="panel-body">
        <h4 class="contact-section">Zhuji City, YinYe Pearl Jewelry Accessories</h4>
        <p class="mb-4">
          <span>Xianyang Village, Shanxia Town, Zhuji City
            <br/>
            Zhejiang Province, China
            <br/>
            P: +86-575-8796-9891
            <br/>
            F: +86-575-8796-9891
            <br/>
            M: +86-159-5852-6600
            <br/>
            E: <a href="mailto:492086843@qq.com">492086843@qq.com</a>
            <br/>
          </span>
        </p>
        <h4 class="contact-section">Shenzhen Meng Yuan Jewellery Co. Ltd.</h4>
        <p class="mb-4">
          <span>Cui Zhu Street, Tin Bei Si Road,
            <br/>
            42 Wan Shan Jewellery Center B1-03.
            <br/>
            Luohu District, Shenzhen
            <br/>
            Guangdong Province, China.
            <br/>
            P: +86-755-2550-9131
            <br/>
            F: +86-755-2550-3921
            <br/>
            M: +86-136-3282-3098
            <br/>
            E: <a href="mailto:IRS138@139.com">IRS138@139.com</a>
          </span>
        </p>
        <h4 class="contact-section">Shenzhen Luohu District Pengji Jewellery</h4>
        <h4 class="contact-section">Accessory Firm</h4>
          <p class="mb-6">
            <span>A13, 1st Floor HeZheng Star Garden,
              <br/>
              Belle South Road, Cuizhu Street,
              <br/>
              Luohu District, Shenzhen
              <br/>
              M: +86-135-0286-3284
              <br/>
              E: <a href="mailto:2655436538@qq.com">2655436538@qq.com</a>
          </span>
        </p>
      </v-expansion-panel-text>
    </v-expansion-panel>
    <v-expansion-panel
      hide-actions
      class="panel panel-default"
    >
      <v-expansion-panel-title class="panel-heading">
        <h4 class="panel-title">South Korea</h4>
      </v-expansion-panel-title>
      <v-expansion-panel-text class="panel-body">
        <h4 class="contact-section">Goldpang Co. Ltd.</h4>
        <p class="mb-6">
          <span>Goldpang Bldg, 
            <br/>
            37-1, Donhwamun-ro, 
            <br/>
            Jongro-gu, Seoul 03139
            <br/>
            Korea
            <br/>
            P: +82 70-4827-2390
            <br/>
            F: +82 70-8668-2665
            <br/>
            E: <a href="mailto:sales@goldpang.com">sales@goldpang.com</a>
            <br/>
          </span>
        </p>
      </v-expansion-panel-text>
    </v-expansion-panel>
    <v-expansion-panel
      hide-actions
      class="panel panel-default"
    >
      <v-expansion-panel-title class="panel-heading">
        <h4 class="panel-title">Philippines</h4>
      </v-expansion-panel-title>
      <v-expansion-panel-text class="panel-body">
        <h4 class="contact-section">S.M. Mangorsi Trading</h4>
        <p class="mb-6">
          <span>Unit S-1080I Ground Floor Shoppesville, 
            <br/>
            Greenhills Shopping Center,
            <br/>
            San Juan City 1502,
            <br/>
            Metro Manila, Philippines
            <br/>
            M: +639 16203 64 33
            <br/>
            E: <a href="mailto:mohdamin132@gmail.com">mohdamin132@gmail.com</a>
            <br/>
          </span>
        </p>
      </v-expansion-panel-text>
    </v-expansion-panel>
    <v-expansion-panel
      hide-actions
      class="panel panel-default"
    >
      <v-expansion-panel-title class="panel-heading">
        <h4 class="panel-title">United Kindgom</h4>
      </v-expansion-panel-title>
      <v-expansion-panel-text class="panel-body">
        <h4 class="contact-section">Cookson Precious Metals Ltd.</h4>
        <p class="mb-6">
          <span>59-83 Vittoria Street,
            <br/>
            Birmingham B1 3NZ,
            <br/>
            United Kingdom
            <br/>
            P: +44-345-100-1122
            <br/>
            F: +44-121-212-6456
            <br/>
            E: <a href="mailto:info@cooksongold.com">info@cooksongold.com</a>
            <br/>
          </span>
        </p>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "ContactUsPanelsPage",
  data() {
    return {
      expandedPanels: []
    }
  }
}
</script>

<style>
.panel-group {
  margin-bottom: 20px !important;
}

.panel {
  background-color: #fff;
  border: 1px solid transparent;
  margin-bottom: 8px !important;
}

.panel-group .panel {
  margin-bottom: 0px;
  border-radius: 4px;
}

.panel-default {
  border-color:#ddd;
}

.panel-heading {
  padding: 10px 15px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: #BE9B59;
}

.panel-title {
  margin-top: 0;
  margin-bottom:0;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
}

.v-expansion-panel-title {
  min-height: 40px !important;
}
</style>
