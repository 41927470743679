<template>
  <div class="pb-12">
    <v-container 
      fluid
    >
      <h2 class="mb-10 pb-2 title-text" >News</h2>
      <v-row>
        <v-col 
          cols="12"
          class="article"
        >
          <div class="act-detail details">
            <div 
              class="act-item"
              :class="{ 'pl-0 pr-0': $vuetify.display.mobile }"
            >
              <div>
                <h2 class="mb-2">Happy Company Award 2022</h2>
                <hr class="title-border">
                <div class="pt-6 pb-4 mb-4 desc">
                  <p>20 June 2022</p>
                </div>
                <v-row>
                  <v-col
                    class="desc-text"
                    :cols="$vuetify.display.mobile ? 12:11"
                  >
                    <p>LeachGarner Hong Kong is honored to receive the “Happy Company Award 2022” 
                    in recognition of our continuous commitment to cultivating and enhancing happiness level of employees in the workplace.</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    :cols="$vuetify.display.mobile ? 12:11"
                  >
                    <v-img 
                      :src="require('@/assets/awards/Happy Company Cert 2022.jpg')"
                      alt="Happy Company Cert"
                      width="100%" 
                      height="100%" 
                      contain
                    >
                    </v-img>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>

export default {
  name: "AwardDetailJune2022Page",
}
</script>
