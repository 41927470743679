<template>
  <div class="pb-12">
    <v-container 
      fluid
    >
      <h2 class="mb-10 pb-2 title-text" >News</h2>
      <v-row>
        <v-col 
          cols="12"
          class="article"
        >
          <div class="act-detail details">
            <div 
              class="act-item"
              :class="{ 'pl-0 pr-0': $vuetify.display.mobile }"
            >
              <div>
                <h2 class="mb-2">LeachGarner Hong Kong Year End Stock Take (Dec 2024)</h2>
                <hr class="title-border">
                <div class="pt-6 pb-4 mb-4 desc">
                  <p>24 December 2024</p>
                </div>
                <v-row>
                  <v-col
                    class="desc-text"
                    :cols="$vuetify.display.mobile ? 12:8"
                  >
                    <p>We will conduct a full year end stock take during the period of 24th – 31st Dec 2024, 
                    all sales invoicing will be resumed on 2nd January 2025.</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    :cols="$vuetify.display.mobile ? 12:9"
                  >
                    <v-img 
                      :src="require('@/assets/news/Stock Take Notice 24-31 Dec 2024.jpg')"
                      alt="Stock Take Dec"
                      width="100%" 
                      height="100%" 
                      contain
                    >
                    </v-img>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>

export default {
  name: "NewsDetailDec2024Page",
}
</script>

