<template>
  <div class="pb-12">
    <v-container fluid>
      <h2 class="title-text" >Certifications</h2>
      <v-row>
        <v-col 
          cols="12"
        >
          <div class="act-item">
            <v-row class="article">
              <v-col
                class="col-sm-12 right-part auto"
              >
                <v-row
                  class="title-div"
                >
                  <v-col 
                    :cols="$vuetify.display.mobile ? 12:5"
                    class="pr-0 pt-0 pb-0"
                    :class="{ 'pb-0': $vuetify.display.mobile }"
                  >
                    <p>22 October 2024</p>
                  </v-col>
                  <v-col
                    :cols="$vuetify.display.mobile ? 12:7"
                    class="pt-0 pb-0"
                    :class="{ 'pt-0': $vuetify.display.mobile }"
                  >
                    <a href="/certsOct2024">
                      <h2 class="blog-title">SMETA Audit Report</H2>
                    </a>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="article">
              <v-col
                class="col-sm-12 right-part auto"
              >
                <v-row
                  class="title-div"
                >
                  <v-col 
                    :cols="$vuetify.display.mobile ? 12:5"
                    class="pr-0 pt-0 pb-0"
                    :class="{ 'pb-0': $vuetify.display.mobile }"
                  >
                    <p>01 September 2022</p>
                  </v-col>
                  <v-col
                    :cols="$vuetify.display.mobile ? 12:7"
                    class="pt-0 pb-0"
                    :class="{ 'pt-0': $vuetify.display.mobile }"
                  >
                    <a href="/certsSept2022">
                      <h2 class="blog-title">Responsible Jewelry Council (RJC) Certificate</H2>
                    </a>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>

export default {
  name: "CertsPage",
}
</script>

<style scoped>

@media (max-width: 991px) {
  .article {
    padding-top: 0px;
  }

  h2.blog-title {
    font-size: 20px;
  }

}
</style>
