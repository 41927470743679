<template>
  <div class="pb-12">
    <v-container 
      fluid
    >
      <h2 class="mb-10 pb-2 title-text" >News</h2>
      <v-row>
        <v-col 
          cols="12"
          class="article"
        >
          <div class="act-detail details">
            <div 
              class="act-item"
              :class="{ 'pl-0 pr-0': $vuetify.display.mobile }"
            >
              <div>
                <h2 class="mb-2">5th Year Caring Company Award (Mar 2023)</h2>
                <hr class="title-border">
                <div class="pt-6 pb-4 mb-4 desc">
                  <p>27 June 2023</p>
                </div>
                <v-row>
                  <v-col
                    class="desc-text"
                    :cols="12"
                  >
                    <p class="mb-4">We are pleased to announce that Leach & Garner (HK) Ltd. has been consecutively awarded the Caring Company Certificate and Logo by the Hong Kong Council of Social Service "HKCSS" this year.
                    It recognizes our commitment in caring of our Community, Employees and Environment over the years.</p>
                    <p>趨時珠寶首飾有限公司今年連續榮獲香港社會服務聯會“社聯”頒發的“商界展關懷”證書及標誌, 碓認我們多年來對關心社區、員工及環境的承諾.</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    :cols="$vuetify.display.mobile ? 12:7"
                  >
                    <v-img 
                      :src="require('@/assets/awards/Caring Company 5th certificate.jpg')"
                      alt="Caring Company 5th Cert"
                      width="100%" 
                      height="100%" 
                      contain
                    >
                    </v-img>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>

export default {
  name: "AwardDetailJun2023Page",
}
</script>