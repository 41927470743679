<template>
  <v-container>
    <h1 style="color: #be9b59">Page Not Found</h1>
    <p>
      This page doesn't exist or it moved somewhere else.
    </p>
  </v-container>  
</template>

<script>
export default {
  name: "NotFoundPage"
}
</script>
