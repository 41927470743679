<template>
  <div class="pb-12">
    <v-container 
      fluid
    >
      <h2 class="mb-10 pb-2 title-text" >News</h2>
      <v-row>
        <v-col 
          cols="12"
          class="article"
        >
          <div class="act-detail details">
            <div 
              class="act-item"
              :class="{ 'pl-0 pr-0': $vuetify.display.mobile }"
            >
              <div>
                <h2 class="mb-2">2024 September Jewelry & Gem World Hong Kong show</h2>
                <hr class="title-border">
                <div class="pt-6 pb-4 mb-46 desc">
                  <p>16 September 2024</p>
                </div>
                <v-row>
                  <v-col
                    :cols="$vuetify.display.mobile ? 12:9"
                  >
                    <v-img 
                      :src="require('@/assets/news/JewelleryGemWorld2024Show_EN.jpg')"
                      alt="Worlds Fine Jewelry"
                      width="100%" 
                      height="100%" 
                      contain
                    >
                    </v-img>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    class="desc-text"
                    :cols="$vuetify.display.mobile ? 12:8"
                  >
                    <p class="mb-4">LeachGarner Hong Kong will exhibit at the Jewellery & Gem WORLD Hong Kong show 2024 from 18th - 24th September 2023, you are cordially invited to visit our 
                    booth located as 1N21 at AsiaWorld – Expo HongKong and 5D110 at Hong Kong Convention & Exhibition Centre.</p>
                    <p>趨時珠寶首飾有限公司將於2024年 9月16日至22日 參加2024年香港珠寶首飾展覽會， 誠邀您蒞臨我們位於亞洲國際博覽館1N21 及香港會議展覽中心5M315的展位參观</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    :cols="$vuetify.display.mobile ? 12:9"
                  >
                    <v-img 
                      :src="require('@/assets/news/Jewellery & GEM World Hong Kong 2024 invitation Card.jpg')"
                      alt="Worlds Fine Jewelry"
                      width="100%" 
                      height="100%" 
                      contain
                    >
                    </v-img>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>

export default {
  name: "NewsDetailSept2024Page",
}
</script>

