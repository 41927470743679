<template>
  <div class="pb-12">
    <v-container 
      fluid
    >
      <h2 class="mb-10 pb-2 title-text" >News</h2>
      <v-row>
        <v-col 
          cols="12"
          class="article"
        >
          <div class="act-detail details">
            <div 
              class="act-item"
              :class="{ 'pl-0 pr-0': $vuetify.display.mobile }"
            >
              <div>
                <h2 class="mb-2">Happy Parent-Child Toy Day</h2>
                <hr class="title-border">
                <div class="pt-6 pb-4 mb-4 desc">
                  <p>10 August 2024</p>
                </div>
                <v-row>
                  <v-col
                    class="desc-text"
                    :cols="$vuetify.display.mobile ? 12:8"
                  >
                    <p>The "Happy Company" organized a "Happy Parent-Child Toy Day" to encourage parents and children to complete educational toys together, aiming to create happy childhood memories and supporting the joyful and healthy growth of the next generation. 
                    LeachGarner Hong Kong was excited to participate in this meaningful activity on 10 August.</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    :cols="$vuetify.display.mobile ? 12:9"
                  >
                    <v-img 
                      :src="require('@/assets/news/20240811_HappyCompany.jpg')"
                      alt="Happy Company"
                      width="100%" 
                      height="100%" 
                      contain
                    >
                    </v-img>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>

export default {
  name: "NewsDetailAug2024Page",
}
</script>

