<template>
  <div class="pb-12">
    <v-container 
      fluid
    >
      <h2 class="mb-10 pb-2 title-text" >Awards</h2>
      <v-row>
        <v-col 
          cols="12"
          class="article"
        >
          <div class="act-detail details">
            <div 
              class="act-item"
              :class="{ 'pl-0 pr-0': $vuetify.display.mobile }"
            >
              <div>
                <h2 class="mb-2">2022 Hong Kong Awards for Environmental Excellence (HKAEE)</h2>
                <hr class="title-border">
                <div class="pt-6 pb-4 mb-4 desc">
                  <p>01 November 2023</p>
                </div>
                <v-row>
                  <v-col
                    :cols="12"
                  >
                    <v-img 
                      :src="require('@/assets/awards/LGHK 2022 Environmental Excellence.jpg')"
                      alt="LGHK 2022 Environmental Excellence"
                      width="100%" 
                      height="100%" 
                      contain
                    >
                    </v-img>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>

export default {
  name: "AwardDetailNov2023Page",
}
</script>
