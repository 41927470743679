<template>
  <div class="pb-12">
    <v-container fluid>
      <h2 class="title-text">Conversion Tables</h2>
      <v-row>
        <v-col cols="12">
          <p>
            <v-img 
              :src="require('@/assets/guidereference/AllConversionTables.jpg')"
              alt="Conversion Table"
              max-width="100%"
              contain
            >
            </v-img>
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>
export default {
  name: "ConversionPage",
}
</script>