<template>
  <div class="pb-12">
    <v-container 
      fluid
    >
      <h2 class="mb-10 pb-2 title-text" >Certifications</h2>
      <v-row>
        <v-col 
          cols="12"
          class="article"
        >
          <div class="act-detail details">
            <div 
              class="act-item"
              :class="{ 'pl-0 pr-0': $vuetify.display.mobile }"
            >
              <div>
                <h2 class="mb-2">Responsible Jewelry Council (RJC) Certificate</h2>
                <hr class="title-border">
                <div class="pt-6 pb-4 mb-4 desc">
                  <p>01 September 2022</p>
                </div>
                <v-row>
                  <v-col
                    class="desc-text"
                    :cols="$vuetify.display.mobile ? 12:10"
                  >
                    <p class="mb-4">We are pleased to announce that LeachGarner is a certified member of the Responsible Jewelry Council (RJC) under the name of our parent company "Richline Group Inc. "
                    This is a remarkable achievement in our sustainability journey and a road map of continuous improvement in partnership with all the stakeholders in the value chain.</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    :cols="$vuetify.display.mobile ? 12:10"
                  >
                    <v-img 
                      :src="require('@/assets/certs/RJC Certificate COP2019US_1.jpg')"
                      alt="RJC Certificate"
                      width="100%" 
                      height="100%" 
                      contain
                    >
                    </v-img>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>

export default {
  name: "CertDetailSept2022Page",
}
</script>