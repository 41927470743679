<template>
  <div class="pb-12">
    <v-container 
      fluid
    >
      <h2 class="title-text" >News</h2>
      <v-row>
        <v-col 
          cols="12"
        >
          <div class="act-item">
            <v-row class="article">
              <v-col
                class="col-sm-12 right-part auto"
              >
                <v-row
                  class="title-div"
                >
                  <v-col 
                    :cols="$vuetify.display.mobile ? 12:5"
                    class="pr-0 pt-0 pb-0"
                    :class="{ 'pb-0': $vuetify.display.mobile }"
                  >
                    <p>24 December 2024</p>
                  </v-col>
                  <v-col
                    :cols="$vuetify.display.mobile ? 12:7"
                    class="pt-0 pb-0"
                    :class="{ 'pt-0': $vuetify.display.mobile }"
                  >
                    <a href="/newsDec2024">
                      <h2 class="blog-title">LeachGarner Hong Kong Year End Stock (Dec 2024)</H2>
                    </a>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="article">
              <v-col
                class="col-sm-12 right-part auto"
              >
                <v-row
                  class="title-div"
                >
                  <v-col 
                    :cols="$vuetify.display.mobile ? 12:5"
                    class="pr-0 pt-0 pb-0"
                    :class="{ 'pb-0': $vuetify.display.mobile }"
                  >
                    <p>16 September 2024</p>
                  </v-col>
                  <v-col
                    :cols="$vuetify.display.mobile ? 12:7"
                    class="pt-0 pb-0"
                    :class="{ 'pt-0': $vuetify.display.mobile }"
                  >
                    <a href="/newsSept2024">
                      <h2 class="blog-title">2024 September Jewerly and Gem World Hong Kong Show</H2>
                    </a>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="article">
              <v-col
                class="col-sm-12 right-part auto"
              >  
                <v-row
                  class="title-div"
                >
                  <v-col 
                    :cols="$vuetify.display.mobile ? 12:5"
                    class="pr-0 pt-0 pb-0"
                    :class="{ 'pb-0': $vuetify.display.mobile }"
                  >
                    <p>10 August 2024</p>
                  </v-col>
                  <v-col
                    :cols="$vuetify.display.mobile ? 12:7"
                    class="pt-0 pb-0"
                    :class="{ 'pt-0': $vuetify.display.mobile }"
                  >
                    <a href="/newsAug2024">
                      <h2 class="blog-title">Happy Parent-Child Toy Day</H2>
                    </a>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="article">
              <v-col
                class="col-sm-12 right-part auto"
              >  
                <v-row
                  class="title-div"
                >
                  <v-col 
                    :cols="$vuetify.display.mobile ? 12:5"
                    class="pr-0 pt-0 pb-0"
                    :class="{ 'pb-0': $vuetify.display.mobile }"
                  >
                    <p>25 June 2024</p>
                  </v-col>
                  <v-col
                    :cols="$vuetify.display.mobile ? 12:7"
                    class="pt-0 pb-0"
                    :class="{ 'pt-0': $vuetify.display.mobile }"
                  >
                    <a href="/newsJun2024">
                      <h2 class="blog-title">LeachGarner Hong Kong Stock Take (June 2024)</H2>
                    </a>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="article">
              <v-col
                class="col-sm-12 right-part auto"
              >  
                <v-row
                  class="title-div"
                >
                  <v-col 
                    :cols="$vuetify.display.mobile ? 12:5"
                    class="pr-0 pt-0 pb-0"
                    :class="{ 'pb-0': $vuetify.display.mobile }"
                  >
                    <p>29 February 2024</p>
                  </v-col>
                  <v-col
                    :cols="$vuetify.display.mobile ? 12:7"
                    class="pt-0 pb-0"
                    :class="{ 'pt-0': $vuetify.display.mobile }"
                  >
                    <a href="/newsFeb2024">
                      <h2 class="blog-title">Invitation of HKTDC International jewerly show 2024</H2>
                    </a>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

export default {
  name: "NewsPage",
}
</script>

<style scoped>
h2.blog-title {
  font-size: 16px;
  font-weight: 600;
  color: #b38707;
  padding-bottom: 10px;
}

.title-div {
  width: 100%;
}

@media (max-width: 991px) {
  .article {
    padding-top: 0px;
  }

  h2.blog-title {
    font-size: 20px;
  }

}

a {
  color: #000;
  text-decoration: none;
}
</style>
